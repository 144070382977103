<template>
  <section class="request-resolved">
    <img src="@/assets/images/happy-icon.svg" class="request-resolved__icon" alt="" />
    <h1 class="hs-popup__title">Happy that we could help!</h1>
    <div class="hs-popup__description">
      <p>We hope you’ve had a pleasant experience.</p>
      <p>Thank you for choosing HomeServe!</p>
    </div>
    <div class="hs-popup__footer">
      <GoToMyDevicesButton />
    </div>
  </section>
</template>

<script>
import GoToMyDevicesButton from '@/components/shared/GoToMyDevicesButton';

export default {
  components: {
    GoToMyDevicesButton,
  },
};
</script>

<style lang="scss">
.request-resolved {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  &__icon {
    margin-bottom: 35px;
  }
}
</style>
